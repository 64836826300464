
import {computed, defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import store from "@/store";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import PermissionService from "@/core/services/PermissionService";

export default defineComponent({
  name: "PermissionForm",
  components: {BaseSelect, UserListSelect, BaseForm},
  props: {
    permission: {},
    id: {type: String}
  },
  emits: ['saved'],
  setup(props) {
    const user = computed(() => store.getters.currentUser);
    const model = ref(props.permission);
    const submitting = ref(false);
    watch(() => props.permission, cb => {
      model.value = cb;
    })
    return {
      user,
      submitting,
      model,
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      if (this.id) {
        PermissionService.update(this.id, this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false
        })
      } else {
        PermissionService.create(this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
