
import {computed, defineComponent, ref} from "vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import {userPermissionStore} from "@/store/permission.store";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import UserLink from "@/views/user/UserLink.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import PermissionForm from "@/views/permission/PermissionForm.vue";
import Swal from "sweetalert2";
import PermissionService from "@/core/services/PermissionService";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "Permissions",
  components: {HelpEmbed, InfoBox, PermissionForm, BaseModal, UserLink, KtDatatable, QuickAction, Entities},
  props: {
    companyId: {}
  },
  setup(props) {
    const state = ref<any>({name: '', id: '', permission: {userIdRequest: '', function: '', companyIdRequest: ''}})
    const objectFilter = ref({companyId: props.companyId})
    const permissionStore = userPermissionStore();
    const page = computed(() => permissionStore.page);
    const headers = [
      {
        name: "User",
        key: "user",
        sortable: false,
      },
      {
        name: "Role",
        key: "role",
        sortable: false,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      }
    ]
    return {
      headers, state,
      page,
      ...PinaFilterObjects(permissionStore, objectFilter.value, ['user'])
    }
  },
  methods: {
    onSaved() {
      const modal = this.$refs.permissionFormRef as typeof BaseModal;
      modal.hideBaseModal();
      this.paginationDataLoad()
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PermissionService.delete(id).then(() => {
            this.paginationDataLoad()
          }).catch(()=>{
            Swal.close();
          })
        },
      })
    },
    add() {
      this.state.name = 'Add New Permission'
      this.state.id = ''
      this.state.permission = {userIdRequest: '', companyIdRequest: this.companyId, function: 'COMPANY_ADMIN'}
      const modal = this.$refs.permissionFormRef as typeof BaseModal;
      modal.showBaseModal();
    }
  }
})
